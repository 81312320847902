import React, { useState } from "react";
import { useEffect } from 'react';
import './App.css';

import Result from './features/result/Result';

import {
    useCheckBackRunningQuery,
    useGetAvailableModelsQuery,
    useLazyQueryModelQuery 
} from './services/lab-explorer';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { FaSearch } from 'react-icons/fa';
import { AiFillInfoCircle } from 'react-icons/ai';

import toast, { Toaster } from 'react-hot-toast';

const App = () => {
    const { data: checkBackRunningData, error: checkBackRunningError } = useCheckBackRunningQuery()
    const { data: getAvailableModelsData, error: getAvailableModelsError, isLoading: getAvailableModelsIsLoading } = useGetAvailableModelsQuery()

    const [availableModels, setAvailableModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(undefined);
    const [modelsDescriptions, setModelsDescriptions] = useState({});
    const [userQuery, setUserQuery] = useState('Study of algorithmic complexity in large distributed graphs.');
    const [queryResult, setQueryResult] = useState(undefined);

    const [ trigger, {data, isLoading, isSuccess, isError} ] = useLazyQueryModelQuery();

    const onSubmit = (event) => {
        if(userQuery==='') {
            toast.error('The query should not be empty.')
        } else {
            trigger({
                model_name: selectedModel,
                n_results: 1500,
                body: {
                    queryText: userQuery
                }
            })
        }
        event.preventDefault();
    }

    useEffect(() => {
        if(data && isSuccess){
            setQueryResult(data)
            toast.success('Query completed successfully.');
        } else if(isError) {
            toast.error('An error occurred during the query.');
        }
    }, [isSuccess, isError, data])

    useEffect(() => {
        if(checkBackRunningError) toast.error('Connection to backend failed.');
        else toast.success('Successful connection to the backend.');
    }, [checkBackRunningData, checkBackRunningError])

    useEffect(() => {
        if(getAvailableModelsError) {
            toast.error('Unable to retrieve models at the moment...');
        } else if(getAvailableModelsData !== undefined) {
            console.log(getAvailableModelsData.available_models)
            setAvailableModels(getAvailableModelsData.available_models)
            setSelectedModel(getAvailableModelsData.available_models[0].abbrv)
            
            var modelDescrs = {...modelsDescriptions}
            getAvailableModelsData.available_models.forEach((model_infos) => {
                let n_authors = model_infos.stats.n_authors.toLocaleString("en-US")
                let n_records = model_infos.stats.n_records.toLocaleString("en-US")
                modelDescrs[model_infos.abbrv] = `${model_infos.description} ${n_authors} authors available based on ${n_records} publications.`
            })
            setModelsDescriptions(modelDescrs)

            if(availableModels.length !== 0) toast.success(availableModels.length+' model(s) found.');
        }
    }, [getAvailableModelsData, getAvailableModelsError, availableModels])

    return (
        <Container className='h-100 main-container'>
            <Toaster />
            <h1>LIRIS Connect</h1>
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Query</Form.Label>
                    <Form.Control 
                        as="textarea" rows={3} 
                        value={userQuery}
                        placeholder="We want a researcher at ease with large graph embeddings and..."
                        onChange={e => {
                            setUserQuery(e.target.value)
                        }}
                        disabled={isLoading}
                    />
                </Form.Group>
                <Form.Group controlId="modelChoice" style={{ display:'inline-block'}}>
                    <Form.Label>Model</Form.Label>
                    <Form.Select
                        style={{display: 'inline-block'}}
                        disabled={isLoading || getAvailableModelsIsLoading} 
                        value={selectedModel} 
                        onChange={(v) => {setSelectedModel(v.target.value)}}
                    >
                        {
                            availableModels.map(
                                (model_infos) => (
                                    <option key={model_infos.abbrv}>{model_infos.abbrv}</option>
                                )
                            )
                        }
                    </Form.Select>
                </Form.Group>
                {
                    modelsDescriptions[selectedModel]!==undefined ?
                    <>
                        &nbsp;&nbsp;
                        <AiFillInfoCircle size={20}/>&nbsp;
                        <i style={{display: 'inline-block'}}>{modelsDescriptions[selectedModel]}</i>
                    </> :
                    ''
                }
                <br />
                <Button 
                    variant="primary"  type="submit" style={{ marginTop:'20px', height: '45px', width: '120px' }}
                    disabled={isLoading || getAvailableModelsIsLoading}
                >   
                    {   
                        (isLoading || getAvailableModelsIsLoading) ?  
                        <Spinner animation="border" variant="light" /> : 
                        <span><FaSearch />  &nbsp;&nbsp;Search</span>
                    }
                </Button>
            </Form>
            <Result pQueryResult={queryResult} />
        </Container>
    );
}

export default App;