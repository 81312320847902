import React, { useState } from "react";
import { useEffect } from 'react';

import PubHistoPlot from './PubHistoPlot';
import AnalyzedText from './AnalyzedText';
import { distanceToSimilarity, pluralize } from '../../utils';

import {
    useGetAuthorByIdQuery
} from '../../services/lab-explorer';

import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

import { BsFillBuildingsFill } from 'react-icons/bs';
import { RiArticleFill } from 'react-icons/ri';
import { BsFillPersonFill } from 'react-icons/bs';

import toast from 'react-hot-toast';

const MAX_AFFS_DISPLAY = 3
const MAX_KEYWORDS_DISPLAY = 5

const AuthorCard = (props) => {
    const { data: getAuthorByIdData, error: getAuthorByIdError, isLoading: getAuthorByIdIsLoading } = useGetAuthorByIdQuery({
        'model_name': props.queryMetadata.model,
        'author_id': props.authorId,
        'body': {
            queryText: props.queryMetadata.query_text
        }
    })

    const [author, setAuthor] = useState(undefined)

    // Variables regarding affiliations
    const [affsCollapse, setAffsCollapse] = useState(true);

    // Variables regarding keywords
    const [keywordsCollapse, setkeywordsCollapse] = useState(true);
    const [keywords, setKeywords] = useState(true);
    
    // Variables regarding publications
    const [pubListDistance, setPubListDistance] = useState([]);
    const [pubListYear, setPubListYear] = useState([]);
    const [pubList, setPubList] = useState(undefined);
    const [pubSort, setPubSort] = useState('distance');

    useEffect(() => {
        setAuthor(undefined)
        setPubList(undefined)
        setkeywordsCollapse(true)
        setAffsCollapse(true)
    }, [props.authorId])

    useEffect(() => {
        if(getAuthorByIdError) {
            toast.error('Error while retrieving author\'s data...')
        } else if(getAuthorByIdData !== undefined && getAuthorByIdData.res !== undefined){
            setAuthor(getAuthorByIdData.res)
            var pubsWithDistance = getAuthorByIdData.res.publications_data.map((pub) => ({
                ...pub
            }))
            setPubListDistance([...pubsWithDistance].sort((a,b) => a.distance-b.distance))
            setPubListYear([...pubsWithDistance].sort((a,b) => b.date.year-a.date.year))

            var parsedKeywords = getAuthorByIdData.res.summary.keywords.map((keyword_w_count) => (`${keyword_w_count[0]} (${keyword_w_count[1]})`))
            setKeywords(parsedKeywords)
        }
    }, [getAuthorByIdData, getAuthorByIdError])
  
    useEffect(() => {
        if(pubSort==='distance') setPubList(pubListDistance)
        else setPubList(pubListYear)
    }, [pubSort, pubListDistance, pubListYear])

    if(author===undefined || getAuthorByIdIsLoading || pubList===undefined) {
        return (
            <div style={{ width:'100%', textAlign:'center' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    let authorSummary = author.summary
    
    return (
        <div style={{ height: '100%', overflowY: 'scroll', backgroundColor:'white', padding: '20px', borderRadius: '10px'}}>
            
            <h1 style={{display:'inline'}}><BsFillPersonFill />&nbsp;{author['forename']} <b>{author['surname']}</b></h1>
            &nbsp;●&nbsp;<i>{authorSummary['author_id']}</i>
            <div style={{ height: '10px' }}/>
            
            <h5>
                {pluralize('publication', authorSummary.n_pubs)}
                ,&nbsp;
                {pluralize('affiliation', authorSummary.n_affs)}
                ,&nbsp;
                active&nbsp;
                {
                    authorSummary.year_range[0]===authorSummary.year_range[1]?
                    <span>in {authorSummary.year_range[0]}</span>:
                    <span>from {authorSummary.year_range[0]} to {authorSummary.year_range[1]}</span>
                }  
            </h5>

            { authorSummary.specs.length === 0 ? <></> : <h6>HAL topics:&nbsp;{authorSummary.specs.join(', ')}</h6> }
            
            <h6>
                HAL keywords:&nbsp;
                {   
                    keywordsCollapse ? 
                    <span>
                        {keywords.slice(0, MAX_KEYWORDS_DISPLAY).join(', ')}
                        {MAX_KEYWORDS_DISPLAY<keywords.length ? <span className='clickableText' onClick={()=>{setkeywordsCollapse(false)}}> (show more)</span> : ''}
                    </span> : 
                    <span>
                        {keywords.join(', ')}
                        <span className='clickableText' onClick={()=>{setkeywordsCollapse(true)}}> (show less)</span>
                    </span>
                }
            </h6>

            <h6>
                Frequent unigrams:&nbsp;
                <span>
                    {
                        authorSummary.ngrams.unigram.map((unigram, i) => (
                            <span>{unigram[0]} ({unigram[1]}){i<9 ? <>,</> : <></>} </span>
                        ))
                    }
                </span>
            </h6>

            <h6>
                Frequent bigrams:&nbsp;
                <span>
                    {
                        authorSummary.ngrams.bigram.map((bigram, i) => (
                            <span>{bigram[0]} ({bigram[1]}){i<9 ? <>,</> : <></>} </span>
                        ))
                    }
                </span>
            </h6>

            <hr/>

            <h4><BsFillBuildingsFill /> {pluralize('affiliation', author.affiliations.length)}</h4>
            <ul>
                {
                    author.affiliations.toReversed().map((aff, i) => (
                        <li key={aff.aff_id} style={i<MAX_AFFS_DISPLAY || !affsCollapse ? {} : {display: 'none'}}>
                            <b>{aff.name}</b>&nbsp;•&nbsp;
                            {
                                aff['addrLine'] === null ?
                                <span>{aff['country']}</span> :
                                <OverlayTrigger overlay={<Tooltip id={aff['aff_id']}>{aff['addrLine']}</Tooltip>}>
                                    <a 
                                        href={"https://www.google.com/maps/search/?api=1&query="+encodeURIComponent(aff['addrLine'])}
                                        target="_blank"
                                        rel='noreferrer'
                                    >
                                        {aff['country']}
                                    </a>
                                </OverlayTrigger>
                            }
                            <br/>
                            {aff['n_pubs']} publication{aff['n_pubs']>1?'s':''}&nbsp;
                            {
                                aff['year_range'][0]===aff['year_range'][1] ?
                                <span>in {aff['year_range'][0]}</span>:
                                <span>from {aff['year_range'][0]} to {aff['year_range'][1]}</span>
                            }
                            <br/>                                                    
                        </li>
                    ))
                }
            </ul>
            {
                author['affiliations'].length>MAX_AFFS_DISPLAY ?
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button variant="primary" onClick={() => setAffsCollapse(!affsCollapse)}>{affsCollapse ? <span>Show more</span> : <span>Show less</span>}</Button>
                    </div>
                :
                    <></>
            }
            
            <hr/>

            <h4 style={{display:'inline'}}><RiArticleFill /> {pluralize('publication', author.publications_data.length)}</h4>
            <small>&nbsp;used in the selected model, {authorSummary.n_pubs} total</small>
            <div style={{height:'15px'}}/>
            <PubHistoPlot pubListDistance={pubListDistance} />
            <span>Sort by:</span>
            <Form.Select defaultValue={pubSort} 
                style={{ width: '200px', display: 'inline-block', marginLeft: '10px', cursor: 'pointer' }}
                onChange={(v) => setPubSort(v.target.value)}
            >
                <option value='distance' style={{cursor: 'pointer'}}>similarity to query</option>
                <option value='year' style={{cursor: 'pointer'}}>year of publication</option>
            </Form.Select>
            <div style={{height:'15px'}}/>
            <Accordion defaultActiveKey="0">
                {
                    pubList.map((pub) => {
                        return (
                            <Accordion.Item key={pub.halId} eventKey={pub.halId}>
                                <Accordion.Header>
                                    <div style={{ width: '90%' }}>
                                        <b style={{ width: '90%' }}>{pub.title==='' ? <i>no title provided for current model</i> : <span>{pub.title}</span>}</b>
                                        <br />
                                        {
                                            pub.date.year===null?
                                            <i>no date provided</i> :
                                            <span>{pub.date.year}</span>
                                        }
                                        &nbsp;•&nbsp;{pluralize('author', pub.authors.length)}
                                        {
                                            pub['halUri']===null ?
                                            <></> :
                                            <>&nbsp;•&nbsp;<a href={pub.halUri} target='__blank'>Open in HAL</a></>
                                        }
                                    </div>
                                    <Badge bg="primary" pill style={{float: 'right'}}>
                                        {distanceToSimilarity(pub.distance).toFixed(2)}
                                    </Badge>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <b>{pluralize('author', pub.authors.length)}:&nbsp;</b>
                                    {
                                        pub.authors.map((author, i)=>(
                                            <span key={author.idno}>
                                                {author.forename} <b>{author.surname}</b>{i+1<pub.authors.length?', ':''}
                                            </span>
                                        ))
                                    }
                                    <br />
                                    <b>{pluralize('keyword', pub.keywords.length)}:</b> {pub.keywords.length>0? pub.keywords.join(', '): <i>no keywords provided</i>}
                                    <br/>
                                    <hr />
                                    <AnalyzedText queryMetadata={props.queryMetadata} text={pub.abstract}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
        </div>
    )
}

export default AuthorCard;