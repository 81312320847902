import React, { useState } from "react";
import { useEffect } from 'react';

import Form from 'react-bootstrap/Form';

import { FaFilter } from 'react-icons/fa';

const ResultFilters = (props) => {
    const [filterCountry, setFilterCountry] = useState(false);
    const [filterActiveFrom, setFilterActiveFrom] = useState(false);
    const [filterActiveFromValue, setFilterActiveFromValue] = useState(2022);
    const [filterMaxNumberOfActiveYears, setFilterMaxNumberOfActiveYears] = useState(false);
    const [filterMaxNumberOfActiveYearsValue, setFilterMaxNumberOfActiveYearsValue] = useState(3);

    useEffect(() => {
        var filters = {
            filterCountry: {
                checked: filterCountry
            },
            filterActiveFrom: {
                checked: filterActiveFrom,
                value: filterActiveFromValue
            },
            filterMaxNumberOfActiveYears: {
                checked: filterMaxNumberOfActiveYears,
                value: filterMaxNumberOfActiveYearsValue
            }
        }
        props.updateFilters(filters)
    }, [filterCountry, filterActiveFrom, filterActiveFromValue, filterMaxNumberOfActiveYears, filterMaxNumberOfActiveYearsValue])

    return (
        <div style={{marginTop:'30px', marginBottom:'30px'}}>
            <FaFilter size={25} style={{display:'inline'}}/>

            <div style={{display:'inline-block', marginLeft: '20px'}}>
                <Form.Check
                    type={'switch'}
                    id={`checkbox-france`}
                    label={`Living in France`}
                    checked={filterCountry}
                    onChange={(e) => {setFilterCountry(e.target.checked)}}
                />
            </div>
            <div style={{color: 'gray', textAlign: 'center', width: '40px', display: 'inline-block'}}>|</div>
            <div style={{display:'inline-block'}}>
                <Form.Check
                    inline
                    type={'switch'}
                    id={`checkbox-recently-active`}
                    label={`Active from:`}
                    checked={filterActiveFrom}
                    onChange={(e) => {setFilterActiveFrom(e.target.checked)}}
                />
                <Form.Select
                    style={{ width: '100px', display: 'inline-block', cursor: 'pointer' }}
                    value={filterActiveFromValue}
                    onChange={(v) => {setFilterActiveFromValue(v.target.value); setFilterActiveFrom(true);}}
                >
                    {
                        [2019, 2020, 2021, 2022, 2023].reverse().map((year) => (
                            <option key={year} value={year} style={{cursor: 'pointer'}}>{year}</option>
                        ))
                    }
                </Form.Select>
            </div>
            <div style={{color: 'gray', textAlign: 'center', width: '40px', display: 'inline-block'}}>|</div>
            <div style={{display:'inline-block'}}>
                <Form.Check
                    inline
                    type={'switch'}
                    id={`checkbox-france`}
                    label={`Max number of active years:`}
                    checked={filterMaxNumberOfActiveYears}
                    onChange={(e) => {setFilterMaxNumberOfActiveYears(e.target.checked)}}
                />
                <Form.Select
                    style={{ width: '80px', display: 'inline-block', cursor: 'pointer' }}
                    value={ filterMaxNumberOfActiveYearsValue }
                    onChange={(v) => {setFilterMaxNumberOfActiveYearsValue(v.target.value); setFilterMaxNumberOfActiveYears(true);}}
                >
                    {
                        [1,2,3,4,5].map((year) => (
                            <option key={year} value={year} style={{cursor: 'pointer'}}>{year}</option>
                        ))
                    }
                </Form.Select>
            </div>
        </div>
    )
}

export default ResultFilters;