import './Result.css';

import AuthorsList from './AuthorsList';
import AuthorCard from './AuthorCard';
// import ResultReview from './ResultReview';
import ResultFilters from './ResultFilters';

import React, { useState } from "react";
import { useEffect } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const N_MAX_AUTHORS = 80;

const Result = (props) => {
    const [queryMetadata, setQueryMetadata] = useState(undefined);
    const [authorList, setAuthorList] = useState([]);
    const [selectedAuthorId, setSelectedAuthorId] = useState(undefined);

    const [filters, setFilters] = useState(undefined);
    const [filteredAuthorList, setFilteredAuthorList] = useState([]);
    // const [authorsWithVotes, setAuthorsWithVotes] = useState([])

    useEffect(() => {
        if(props.pQueryResult !== undefined && props.pQueryResult.res !== undefined) {
            setQueryMetadata({...props.pQueryResult.metadata})
            setSelectedAuthorId(props.pQueryResult.res[0]['author_id'])
            setAuthorList(props.pQueryResult.res.map((x) => {
                return {...x}
            }))
            // setAuthorsWithVotes([])
        }
    }, [props.pQueryResult])

    useEffect(() => {
        if(authorList.length > 0){
            var AuthorList = authorList.map((x) => {
                return {...x}
            })

            if(filters !== undefined){
                // Country filter
                if(filters.filterCountry.checked) {
                    AuthorList = AuthorList.filter((author) => {
                        let author_lkl = author.author_summary.last_known_loc
                        if (author_lkl !== null){
                            if(author_lkl.country !== null) return author_lkl.country === 'France'
                            else return false
                        } else {
                            return false
                        }
                    })
                }
                // Active in filter 
                if(filters.filterActiveFrom.checked) {
                    AuthorList = AuthorList.filter((author) => {
                        let author_yr = author.author_summary.year_range
                        if (author_yr !== null){
                            let filter_value = filters.filterActiveFrom.value
                            if(filter_value <= author_yr[0]) return true
                            else return false
                        } else {
                            return false
                        }
                    })
                }
                // Max number of active years filter 
                if(filters.filterMaxNumberOfActiveYears.checked) {
                    AuthorList = AuthorList.filter((author) => {
                        let author_yr = author.author_summary.year_range
                        if (author_yr !== null){
                            let filter_value = filters.filterMaxNumberOfActiveYears.value
                            if((author_yr[1]-author_yr[0])<=filter_value) return true
                            else return false
                        } else {
                            return false
                        }
                    })
                }
            }

            setSelectedAuthorId(AuthorList[0]['author_id'])
            setFilteredAuthorList(AuthorList)
        } else {
            setSelectedAuthorId(undefined)
            setFilteredAuthorList([])
        }
    }, [filters, authorList])

    // const registerVote = (author_id) => { setAuthorsWithVotes([author_id, ...authorsWithVotes]) }

    if(queryMetadata === undefined) {
        return (
            <Container> 
                <div style={{ marginTop:'40px'}} />
                <hr />
                <Alert variant='secondary'>
                    Launch a query to display some results !
                </Alert>
            </Container>
        )
    } else {
        var author_list_content = <></>
        if(authorList === undefined) {
            author_list_content = <>
                <div style={{ width:'100%', textAlign:'center' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            </>
        } else {
            author_list_content = <>
                <Container style = {{ backgroundColor:'#DDD', minHeight: '100px', maxHeight:'1040px', borderRadius: '5px', padding: '20px'}}>
                    <Row>
                        <Col xs={4} style = {{ height: '1000px', borderRight: '3px solid #BBB' }}>
                            <AuthorsList 
                                authorList = { filteredAuthorList.slice(0, N_MAX_AUTHORS) }
                                selectedAuthorId = { selectedAuthorId }
                                selectAuthor = { setSelectedAuthorId }
                            />
                        </Col>
                        <Col xs={8} style={{ height: '910px', paddingLeft: '20px' }}>
                            {
                                selectedAuthorId === undefined ? <p>undefined</p> :
                                <>
                                    {/* <ResultReview 
                                        authorId = { selectedAuthorId }
                                        modelName = { queryMetadata.model }
                                        voteRegistered = { authorsWithVotes.includes(selectedAuthorId) }
                                        registerVote = { registerVote }
                                    /> */}
                                    <AuthorCard
                                        authorId = { selectedAuthorId }
                                        queryMetadata = { queryMetadata }
                                    />
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        }

        return (
            <Container> 
                <div style={{ marginTop:'40px'}} ></div>
                <hr></hr>
                <h3>Result</h3>
                <Alert variant='secondary'>
                    {queryMetadata.query_text}
                    <br />
                    <small><i>
                        model: {queryMetadata.model_infos.name} |&nbsp;
                        execution time: {queryMetadata.executation_time.toFixed(2)}s |&nbsp;
                        {queryMetadata.time}
                    </i></small>
                </Alert>                

                <ResultFilters updateFilters={setFilters}/>

                {author_list_content}
                
            </Container>
        )
    }
}

export default Result;