import React from "react";

import { distanceToSimilarity } from '../../utils';

import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

const AuthorsList = (props) => {
    return (
        <ListGroup as="ol" numbered style={{width: '100%', overflowY: 'scroll', maxHeight:'100%'}}>
            {
                props.authorList.map(
                    (author) => {
                        let authorId = author.author_id
                        let authorSummary = author.author_summary
                        let authorCountry = undefined
                        if (authorSummary.last_known_loc !== null && authorSummary.last_known_loc.country !== null)
                            authorCountry = authorSummary.last_known_loc.country
                        return (
                            <ListGroup.Item 
                                as="li" 
                                id = { authorId } 
                                key = { authorId } 
                                className = 'author-elem align-items-start noselect'
                                active = { authorId === props.selectedAuthorId ? true : false }
                                onClick = { () => props.selectAuthor(authorId) }
                            >
                                {authorSummary.name.forename} <b>{authorSummary.name.surname}</b>
                                <Badge bg="primary" pill style={{float: 'right'}}>
                                    {distanceToSimilarity(author.distance).toFixed(2)}
                                </Badge>
                                <br/>
                                <small>
                                    {authorSummary.n_pubs} publication{authorSummary.n_pubs>1?'s':''}
                                    ,&nbsp;
                                    {authorSummary.n_affs} affiliation{authorSummary.n_affs>1?'s':''}
                                    <br />
                                    Active&nbsp;
                                    {
                                        authorSummary.year_range[0]===authorSummary.year_range[1]?
                                        <span>in {authorSummary.year_range[0]}</span>:
                                        <span>from {authorSummary.year_range[0]} to {authorSummary.year_range[1]}</span>
                                    }                               
                                </small>
                                <br/>
                                <small>Last seen in {authorCountry === undefined ? <i>unknown</i> : authorCountry}</small>
                            </ListGroup.Item>
                        )
                    }
                )
            }
        </ListGroup>
    )
}

export default AuthorsList;