import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { labexplorerApi } from './services/lab-explorer'

export const store = configureStore({
  reducer: {
    [labexplorerApi.reducerPath]: labexplorerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(labexplorerApi.middleware),
})

setupListeners(store.dispatch)