import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const labexplorerApi = createApi({
  reducerPath: 'labexplorerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://liris-connect.datavalor.com/api/'
    // baseUrl: 'http://back:5000/' 
    // baseUrl: 'http://localhost:5000/' 
  }),
  endpoints: (builder) => ({
    checkBackRunning: builder.query({
        query: () => {
            return {
              url: '/',
              method: 'GET'
            }
        },
    }),
    getAvailableModels: builder.query({
      query: () => {
          return {
            url: '/models',
            method: 'GET'
          }
      },
    }),
    queryModel: builder.query({
        query: (args) => {
            let url = `${args.model_name}/query/${args.n_results}`
            console.log(`Query url: ${url}`)
            return {
              url: url,
              method: 'POST',
              body: args.body
            }
        },
    }),
    getAuthorById: builder.query({
        query: (args) => {
            let url = `${args.model_name}/authors/${args.author_id}`
            console.log(`Query url: ${url}`)
            return {
              url: url,
              method: 'POST',
              body: args.body
            }
        },
    }),
    getTextAnalysis: builder.query({
      query: (args) => {
          let url = `${args.model_name}/text`
          console.log(`Query url: ${url}`)
          console.log(args.body)
          return {
            url: url,
            method: 'POST',
            body: args.body
          }
      },
  }),
    reviewResult: builder.query({
      query: (args) => {
          let url = `${args.model_name}/vote/${args.author}/${args.vote}`
          console.log(`Query url: ${url}`)
          return {
            url: url,
            method: 'PUT'
          }
      },
    }),
  }),
})

export const { 
  useCheckBackRunningQuery,
  useGetAvailableModelsQuery,
  useLazyQueryModelQuery,
  useGetAuthorByIdQuery,
  useLazyReviewResultQuery,
  useLazyGetTextAnalysisQuery
} = labexplorerApi