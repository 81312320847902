import Plot from 'react-plotly.js';

import { distanceToSimilarity } from '../../utils';

const PubHistoPlot = (props) => {
    var year_analysis = {}
    props.pubListDistance.forEach(pub => {
        let year = pub.date.year
        if(year_analysis[year] === undefined){
            year_analysis[year] = {
                'count': 0,
                'similarities_sum': 0
            }
        } 
        year_analysis[year].count += 1
        year_analysis[year].similarities_sum += distanceToSimilarity(pub.distance)
    });
    
    var [pubYears, pubYearCounts, pubYearSimilarities] = [[], [], []]
    for (const [key, value] of Object.entries(year_analysis)) {
        pubYears.push(key)
        pubYearCounts.push(value.count)
        pubYearSimilarities.push(value.similarities_sum/value.count)
    }

    var distanceColor = '#0d6efd'
    var countColor = 'black'

    var distanceTrace = {
        type: 'scatter',
        x: pubYears,
        y: pubYearSimilarities,
        mode: 'lines+markers',
        yaxis: 'y2',
        line: {width: 3},
        marker: {color: distanceColor},
        // name: 'Subject interest',
        z: 3
    }

    var countTrace = {
        type: 'bar',
        x: pubYears,
        y: pubYearCounts,
        marker: {color: countColor, width: 5},
        // name: 'Author activity',
        width: 0.5,
        z: 2
    }

    return (
        <Plot
            data={[ countTrace, distanceTrace]}
            layout={ 
                {
                    width: '100%', 
                    height: 240, 
                    margin:{ l:40, r:40, t:0, b:50 },
                    showlegend: false,
                    xaxis: {
                        title: 'years'
                    }, 
                    maxBarWidth: 0.1,
                    yaxis: {
                        title: 'Number of publications',
                        titlefont: {color: countColor},
                        tickfont: {color: countColor},
                    },
                    yaxis2: {
                        title: 'Averaged similarity',
                        overlaying: 'y',
                        side: 'right',
                        titlefont: {color: distanceColor},
                        tickfont: {color: distanceColor},
                    }
                    // yaxis_title: 'average publications distances'
                } 
            }
        />
    )
}

export default PubHistoPlot;