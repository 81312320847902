import React, { useState } from "react";
import { useEffect } from 'react';

import { distanceToSimilarity } from '../../utils';

import {
    useLazyGetTextAnalysisQuery
} from '../../services/lab-explorer';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { ImLab } from 'react-icons/im'

import toast from 'react-hot-toast';

const AnalyzedText = (props) => {
    const [ trigger, {data, isLoading, isSuccess, isError} ] = useLazyGetTextAnalysisQuery();

    const [analyzedText, setAnalyzedText] = useState(undefined);
    
    const analyzeText = (text) => {
        if(text!==undefined && text!==''){
            trigger({
                model_name: props.queryMetadata.model,
                body: {
                    queryText: props.queryMetadata.query_text,
                    textToAnalyze: text
                }
            })
        }   
    }

    useEffect(() => {
        if(data && isSuccess){
            
            var analysisResult = []
            let sim_min = 1
            let sim_max = 0
            data.res.forEach((cutWithDistance) => {
                let similarity = distanceToSimilarity(cutWithDistance[0])
                if(sim_min>similarity) sim_min=similarity
                if(sim_max<similarity) sim_max=similarity
            })
            let delta_sim = sim_max-sim_min
            let thresold = 0.3
            data.res.forEach((cutWithDistance) => {
                let similarity = distanceToSimilarity(cutWithDistance[0])
                let norm_similarity = (similarity-sim_min)/delta_sim
                if(norm_similarity<thresold) norm_similarity=0
                else norm_similarity = (norm_similarity-thresold)/(1-thresold)

                analysisResult.push({
                    key: `${cutWithDistance[1][0]}-${cutWithDistance[1][1]}`,
                    start: cutWithDistance[1][0],
                    end: cutWithDistance[1][1],
                    similarity: similarity,
                    norm_similarity: norm_similarity
                })
            })
            setAnalyzedText(analysisResult)
            toast.success('Analysis completed successfully.');
        } else if(isError) {
            toast.error('An error occurred during the analysis.');
        }
    }, [isSuccess, isError, data])

    return (
        <>
            <p style={{textAlign: 'justify'}}>
                {   
                    analyzedText === undefined ? <span>{props.text}</span> :
                    analyzedText.map((textCut) => {
                        // console.log(textCut)
                        return (
                            <span
                                key={textCut.key}
                                style={{
                                    background: `rgba(252, 319, 3, ${textCut.norm_similarity})`
                                }}
                            >
                                {props.text.slice(textCut.start, textCut.end)}
                            </span>
                        )
                    })       
                }
            </p>                
            <Button style={{ width: '130px', height: '45px' }}onClick={() => analyzeText(props.text)}>
                {isLoading ? <Spinner style={{ width: '30px', height: '30px' }} animation="border" variant="light" /> : <><ImLab />&nbsp;Analyze</>}
            </Button>            
        </>   
    )
}

export default AnalyzedText;